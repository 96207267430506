import React from 'react';
import { PageTitle } from "../../layout/PageTitle";
import SponsorPaper from "../../layout/components/SponsorPaper";
import Box from "@material-ui/core/Box";
import { Button, Checkbox, CircularProgress, FormControlLabel, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Cookies } from "react-cookie";
import { StateContext } from "../../discord/StateProvider";
import { Constants } from "../../constants";
import { Alert, AlertTitle } from "@material-ui/lab";
import { SnackbarContext } from "../../context/SnackbarProvider";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const getPlayers = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/players`);
    return await r.json();
}

export const isSignedUp = async (teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/${teamId}/existsSignup`);
    return await r.json();
}

const registrationState = async () => {
    const r = await fetch(`${Constants.apiPrefix}/api/settings/registration`)
    return await r.json();
}

export const signupForSeason = async (accessToken, teamId) => {
    const r = await fetch(`${Constants.apiPrefix}/api/team/signupForSeason`, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            accessToken: accessToken,
            teamId: teamId
        }),
    });
    return await r.json();
}

export const TeamSignup = (props) => {
    const theme = useTheme();
    const classes = useStyles();
    const cookies = new Cookies();
    const { t } = useTranslation();
    const history = useHistory();
    const [successSignup, setSuccessSignup] = React.useState(false);
    const [checked, setChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [loggedIn, setLoggedIn] = React.useState(undefined);
    const [teamCaptain, setTeamCaptain] = React.useState(undefined);
    const [haveTeam, setHaveTeam] = React.useState(undefined);
    //const [threeMembers, setThreeMembers] = React.useState(undefined);
    const [pageLoading, setPageLoading] = React.useState(false);
    const { currentUser, currentTeam } = React.useContext(StateContext);
    const { setSnackbar } = React.useContext(SnackbarContext);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleClick = (event) => {
        setLoading(true);
        signupForSeason(cookies.get("accessToken"), currentTeam.id)
            .then(response => {
                if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                    setSnackbar({
                        open: true,
                        severity: "success",
                        message: t(response.MESSAGE)
                    });
                    setSuccessSignup(true);
                } else {
                    setSnackbar({
                        open: true,
                        severity: "error",
                        message: t(response.MESSAGE)
                    });
                }
                setLoading(false);
            });
    }

    // Make sure the user is the team captain
    React.useEffect(() => {
        setPageLoading(true);
        // Make sure the user is logged in
        if (cookies.get("accessToken")) {
            setLoggedIn(true);
        } else {
            setLoggedIn(false);
        }
        if (currentUser && currentTeam) {
            Promise.all([
                getPlayers(currentTeam.id),
                isSignedUp(currentTeam.id),
                registrationState()
            ]).then(([players, signedUp, registration]) => {
                console.log(registration);
                if (players) {
                    setTeamCaptain(players.some((player) => (
                        player.id === currentUser.playerId && player.sortorder === 1
                    )));
                }
                if (signedUp.MESSAGE) {
                    setSnackbar({
                        open: true,
                        severity: "info",
                        message: t("signup.already-signed.snackbar")
                    });
                    history.push("/");
                }
                if (registration.value.toLowerCase() === "false") {
                    setSnackbar({
                        open: true,
                        severity: "info",
                        message: t("signup.registration_closed.snackbar")
                    });
                    history.push("/");
                }
            });
            setPageLoading(false);
            setHaveTeam(true);
        }
        if (!currentTeam) {
            setHaveTeam(false);
            setPageLoading(false);
        }
    }, [currentTeam, currentUser]);

    let errorMessage = undefined;
    if (loggedIn === false) {
        errorMessage = (
            <Alert severity="info" variant="outlined">
                <AlertTitle><Trans>signup.not-logged-in.title</Trans></AlertTitle>
                <Trans>signup.not-logged-in.text</Trans>
            </Alert>
        );
    } else if (haveTeam === false) {
        errorMessage = (
            <Alert severity="info" variant="outlined">
                <AlertTitle><Trans>signup.no-team.title</Trans></AlertTitle>
                <Trans>signup.no-team.text</Trans>
            </Alert>
        );
    } else if (teamCaptain === false) {
        errorMessage = (
            <Alert severity="info" variant="outlined">
                <AlertTitle><Trans>signup.not-team-captain.title</Trans></AlertTitle>
                <Trans>signup.not-team-captain.text</Trans>
            </Alert>
        );
    } /*else if(threeMembers === false) {
    errorMessage = (
      <Alert severity="info" variant="outlined">
        <AlertTitle><Trans>signup.notEnoughMembers.title</Trans></AlertTitle>
        <Trans>signup.notEnoughMembers.text</Trans>
      </Alert>
    );
  }*/

    if (successSignup) {
        return (
            <>
                <PageTitle shadow><Trans>signup.title</Trans></PageTitle>
                <SponsorPaper>
                    <Box pt={1} />
                </SponsorPaper>
                <Box my={5} />
                <Container>
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Alert severity="success" variant="outlined">
                                <Typography variant="body1">
                                    <Trans>signup.complete.freetext.part1</Trans>
                                </Typography>
                            </Alert>
                        </Grid>
                    </Grid>
                </Container>

                <Box my={10} />
            </>
        )
    }

    return (
        <>
            <PageTitle shadow><Trans>signup.title</Trans></PageTitle>
            <SponsorPaper>
                <Box pt={1} />
            </SponsorPaper>
            <Box my={5} />
            <Container>
                {pageLoading && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                )}
                {errorMessage && errorMessage}
                {currentTeam && currentUser && loggedIn === true && teamCaptain === true && (
                    <Grid container direction="column" spacing={2}>
                        <Grid item xs>
                            <Typography>
                                <Trans>signup.freetext.part1</Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography>
                                <Trans>signup.list-info.title</Trans>
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        <Trans>signup.list-info.part1</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.list-info.part2</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.list-info.part3</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.list-info.part4</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.list-info.part5</Trans>
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                        <Grid item xs>
                            <Typography>
                                <Trans>signup.important-info.title</Trans>
                            </Typography>
                            <ul>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part1</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part2</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part3</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part4</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part5</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part6</Trans>
                                    </Typography>
                                </li>
                                <br />
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part7</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part8</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part9</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part10</Trans>
                                    </Typography>
                                </li>
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part11</Trans>
                                    </Typography>
                                </li>
                                <br />
                                <li>
                                    <Typography>
                                        <Trans>signup.important-info.part12</Trans>
                                    </Typography>
                                </li>
                            </ul>
                            <br />
                            <Typography>
                                <Trans i18nKey="signup.important-info.rule-link">
                                    <Link className="srl-link" to="/rules">rules-link</Link>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography style={{ fontWeight: 800, color: theme.palette.secondary.main }}>
                                <Trans>signup.endtext.part1</Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <Typography>
                                <Trans i18nKey="signup.endtext.part2">
                                    <a href="https://discord.com/channels/549361926140854322/818624487024099389" className="srl-link" target="_blank">external-link</a>
                                </Trans>
                            </Typography>
                        </Grid>
                        <Grid item xs>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} name="readSignupText" />}
                                label={t("signup.checkbox.text")}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <div className={classes.wrapper}>
                                <Button
                                    fullWidth
                                    onClick={handleClick}
                                    variant="contained"
                                    color="secondary"
                                    disabled={loading || !checked}
                                >
                                    <Trans>signup.button.accept</Trans>
                                </Button>
                                {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                        </Grid>
                    </Grid>
                )}

            </Container>

            <Box my={10} />
        </>
    )
}