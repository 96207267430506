import React, { useContext } from "react";
import { Redirect, useParams } from "react-router";
import Container from "@material-ui/core/Container";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import { Alert, AlertTitle, Autocomplete } from "@material-ui/lab";
import { FaDiscord } from "react-icons/all";
import Cookies from "universal-cookie";
import Typography from "@material-ui/core/Typography";
import { Trans, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import { DiscordAuthentication } from "./DiscordAuthentation";
import { StateContext } from "./StateProvider";
import { Constants } from "../constants";
import { TextField } from "../components/TextField";
import { PageTitle } from "../layout/PageTitle";
import SponsorPaper from "../layout/components/SponsorPaper";
import { SnackbarContext } from "../context/SnackbarProvider";
import { swedishCountries } from "../components/swedishCountries";
import { GoogleSpreadsheet } from "google-spreadsheet";

async function existsInSRLDatabase(accessToken) {
    let response = await fetch(new URL(`/api/user/hidden/${accessToken}/exists`, Constants.apiPrefix).toString());
    return await response.json();
}

async function isInSRLDiscord(accessToken) {
    let response = await fetch(new URL(`/api/user/hidden/${accessToken}/guilds`, Constants.apiPrefix).toString());
    const guilds = await response.json();
    return guilds.length > 0 && guilds.some((guild) => guild.id === Constants.SvenskaRaketliganGuildId);
}

async function isMember(accessToken) {
    let response = await fetch(new URL(`/api/user/is-member/${accessToken}`, Constants.apiPrefix).toString());
    const member = await response.json();
    return member;
}

async function checkGoogleSheet(accessToken) {
    // Get users email filled in on profile
    const response = await fetch(new URL(`/api/user/properties/${accessToken}`, Constants.apiPrefix).toString());
    const user = await response.json();
    const userEmail = user.MESSAGE.discordEmail;

    // integrate to Google Sheets and check if email exists
    let userFound = false;
    try {
        const doc = new GoogleSpreadsheet(Constants.googleDocId);

        await doc.useServiceAccountAuth({
            client_email: Constants.googleClientEmail,
            private_key: Constants.googlePrivateKey,
        });

        await doc.loadInfo();

        const sheet = doc.sheetsByIndex[0];
        const rows = await sheet.getRows();

        for (const row of rows) {
            if (row["E-postadress"] === userEmail) {
                userFound = true;
                break;
            }
        }

        if (!userFound) {
            return false;
        } else {
            let response = await fetch(new URL(`/api/user/setMember`, Constants.apiPrefix).toString(), {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    accessToken: accessToken,
                }),
            });
            const res = await response.json();
            if (res.CODE === 200) {
                return true;
            }
            return false;
        }
    } catch (e) {
        return false;
    }
}

async function createUserInSRLDatabase(accessToken, registerForm) {
    let response = await fetch(new URL(`/api/user/`, Constants.apiPrefix).toString(), {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            accessToken: accessToken,
            platform: registerForm.platform,
            platformId: registerForm.platformId,
            birthdate: registerForm.birthdate,
            nationality: registerForm.nationality,
        }),
    });
    return await response.json();
}

async function createNewUserInSRLDatabase(accessToken, registerForm) {
    let response = await fetch(new URL(`/api/newUser/`, Constants.apiPrefix).toString(), {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            accessToken: accessToken,
            nickname: registerForm.nickname,
            platform: registerForm.platform,
            platformId: registerForm.platformId,
            birthdate: registerForm.birthdate,
            nationality: registerForm.nationality,
        }),
    });
    return await response.json();
}

async function checkIfCanConnectToPlayer(accessToken) {
    const r = await fetch(`${Constants.apiPrefix}/api/user/canConnectToPlayer`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            accessToken: accessToken,
        }),
    });
    return await r.json();
}

export async function getUser(accessToken) {
    const response = await fetch(new URL(`/api/user/hidden/${accessToken}/full`, Constants.apiPrefix).toString());
    return await response.json();
}

export async function getTeam(accessToken) {
    const response = await fetch(new URL(`/api/team/hidden/${accessToken}/currentTeam`, Constants.apiPrefix).toString());
    return await response.json();
}

export async function getNotifications(accessToken) {
    const response = await fetch(new URL(Constants.apiPrefix + `/api/user/hidden/${accessToken}/notifications`).toString());
    return await response.json();
}

export function logout(cookies, setLoggedIn) {
    DiscordAuthentication.revokeToken(cookies.get("accessToken")).catch((e) => {
        console.log("Could not revoke accessToken: " + e.message);
    });
    cookies.remove("accessToken", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    setLoggedIn(false);
}

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));

export const Callback = (props) => {
    const iso3311a2 = require("iso-3166-1-alpha-2");
    const searchParams = new URLSearchParams(props.location.search.substring(1));
    let { callbackCode } = useParams();
    const cookies = new Cookies();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { setSnackbar } = React.useContext(SnackbarContext);

    const [state, setState] = React.useState({
        loaded: false,
        loginStatus: "",
        registerPageDialogOpen: false,
    });

    const [registerForm, setRegisterForm] = React.useState({
        nickname: undefined,
        platform: "",
        platformId: undefined,
        birthdate: undefined,
        nationality: undefined,
    });

    const [tokens, setTokens] = React.useState();

    const setLoggedIn = useContext(StateContext).setLoggedIn;

    const handleRegisterFormChange = (event) => {
        const name = event.target.name;
        setRegisterForm({
            ...registerForm,
            [name]: event.target.value,
        });
    };

    const clearRegisterForm = () => {
        setRegisterForm({
            nickname: undefined,
            platform: "",
            platformId: undefined,
            birthdate: undefined,
            nationality: undefined,
        });
    };

    const handleRegisterDialogOpen = () => {
        setState({
            ...state,
            registerPageDialogOpen: true,
        });
    };

    const handleRegisterDialogClose = () => {
        setState({
            ...state,
            registerPageDialogOpen: false,
        });
        clearRegisterForm();
    };

    

    const registerFormValid = () => {
        return (
            registerForm.nationality &&
            registerForm.nationality.length > 0 &&
            registerForm.platform &&
            registerForm.platform.length > 0 &&
            registerForm.platformId &&
            registerForm.platformId.length > 0 &&
            registerForm.birthdate &&
            registerForm.birthdate.length > 0
        );
    };

    const registerFormValidWithNickname = () => {
        return (
            registerForm.nationality &&
            registerForm.nationality.length > 0 &&
            registerForm.platform &&
            registerForm.platform.length > 0 &&
            registerForm.platformId &&
            registerForm.platformId.length > 0 &&
            registerForm.birthdate &&
            registerForm.birthdate.length > 0 &&
            registerForm.nickname &&
            registerForm.nickname.length > 0
        );
    };

    const formRef = React.useRef();
    const [helpText, setHelpText] = React.useState();

    const setCookiesAndSetToLoggedIn = () => {
        cookies.set("accessToken", tokens.accessToken, tokens.options);
        cookies.set("refreshToken", tokens.refreshToken, tokens.options);
        setLoggedIn(true);
    };

    const registerAsNewPlayer = () => {
        setLoading(true);
        createNewUserInSRLDatabase(tokens.accessToken, registerForm).then((response) => {
            if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                setState({
                    ...state,
                    loaded: true,
                    loginStatus: "success",
                    registerPageDialogOpen: false,
                });
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE),
                });
                setCookiesAndSetToLoggedIn();
                setLoading(false);
            } else if (response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE),
                });
                setLoading(false);
            } else {
                throw new Error(`react.discord.Callback.registerAsNewPlayer - ${JSON.stringify({ response: response })}`);
            }
        });
    };

    const handleAccept = () => {
        setLoading(true);
        createUserInSRLDatabase(tokens.accessToken, registerForm).then((response) => {
            // Check if we could create user in database
            if (response.hasOwnProperty("CODE") && response.CODE === 200) {
                setState({
                    ...state,
                    loaded: true,
                    loginStatus: "success",
                });
                setCookiesAndSetToLoggedIn();
                setSnackbar({
                    open: true,
                    severity: "success",
                    message: t(response.MESSAGE),
                });
            } else if (response.hasOwnProperty("CODE") && response.CODE !== 500) {
                setSnackbar({
                    open: true,
                    severity: "error",
                    message: t(response.MESSAGE),
                });
            } else {
                throw new Error("react.discord.Callback.handleAccept");
            }
            setLoading(false);
        });
    };

    const [checked, setChecked] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    if (callbackCode === "login") {
        // const tokenType = searchParams.get("token_type");
        const code = searchParams.get("code");
        if (code === null && searchParams.get("error") !== null) {
            return <Redirect to="/" />;
        }
        const callbackState = searchParams.get("state");
        if (callbackState !== sessionStorage.getItem("state")) {
            // Do something here, it should be the same
        }

        //

        // Get the discord tokens
        React.useEffect(() => {
            DiscordAuthentication.getAccessToken(code)
                .then((response) => {
                    let expires = new Date();
                    expires.setTime(expires.getTime() + response.expiresIn * 1000);
                    setTokens({
                        accessToken: response.accessToken,
                        refreshToken: response.refreshToken,
                        options: { path: "/", expires },
                    });
                })
                .catch((error) => {
                    console.log(error);
                    setState({
                        ...state,
                        loaded: true,
                        loginStatus: "error",
                    });
                });
        }, []);

        React.useEffect(() => {
            if (tokens !== undefined) {
                existsInSRLDatabase(tokens.accessToken).then((exists) => {
                    if (exists === true) {
                        isMember(tokens.accessToken).then((r) => {
                            if (r.CODE == 200) {
                                setState({
                                    ...state,
                                    loaded: true,
                                    loginStatus: "success",
                                });
                                setCookiesAndSetToLoggedIn();
                            } else {
                                setState({
                                    ...state,
                                    loaded: true,
                                    loginStatus: "notMember",
                                });
                            }
                        });
                    } else {
                        isInSRLDiscord(tokens.accessToken).then((inDiscord) => {
                            if (inDiscord === true) {
                                checkIfCanConnectToPlayer(tokens.accessToken).then((response) => {
                                    if (response.hasOwnProperty("CODE") && response.CODE === 200 && response.MESSAGE === true) {
                                        // Här vill vi godkänna att spara användarinformation innan man går vidare
                                        setState({
                                            ...state,
                                            loaded: true,
                                            loginStatus: "waitingInformation",
                                        });
                                    } else {
                                        setState({
                                            ...state,
                                            loaded: true,
                                            loginStatus: "notConnectedToPlayer",
                                        });
                                    }
                                });
                            } else {
                                DiscordAuthentication.revokeToken(tokens.accessToken).then((r) => console.log("Token revoked: " + r));
                                setState({
                                    ...state,
                                    loaded: true,
                                    loginStatus: "notMemberOfGuild",
                                });
                                setTokens(undefined);
                            }
                        });
                    }
                });
            }
        }, [tokens]);

        if (state.loaded) {
            switch (state.loginStatus) {
                case "":
                    break;
                case "success":
                    return <Redirect to="/" />;
                case "notMember":
                    return (
                        <>
                            <PageTitle />
                            <SponsorPaper>
                                <Box pt={1} />
                            </SponsorPaper>
                            <Box my={5} />
                            <Container>
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Alert severity="error">
                                            <Trans>login.not-member</Trans>
                                        </Alert>
                                        <Alert severity="warning">
                                            <Trans>login.member-email</Trans>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" href={"/member"}>
                                            <Trans>login.become-member</Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box my={35} />
                        </>
                    );
                case "notMemberOfGuild":
                    return (
                        <>
                            <PageTitle />
                            <SponsorPaper>
                                <Box pt={1} />
                            </SponsorPaper>
                            <Box my={5} />
                            <Container>
                                <Grid container spacing={3}>
                                    <Grid item xs={8}>
                                        <Alert severity="error">
                                            <Trans>login.user-not-in-discord</Trans>
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" href={"https://discord.gg/svenskaraketligan"}>
                                            <FaDiscord />
                                            <Trans>login.join-srl-discord-button</Trans>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box my={35} />
                        </>
                    );
                case "notConnectedToPlayer":
                    return (
                        <>
                            <PageTitle minHeight={300}></PageTitle>
                            <SponsorPaper>
                                <Box py={1} />
                            </SponsorPaper>
                            <Box my={5} />
                            <Container>
                                <Grid container alignItems="center" direction="row" justify="center">
                                    <Grid container item xs={12} md={6} direction="column" spacing={1}>
                                        <Grid item style={{ marginBottom: 25 }}>
                                            <Alert severity="info" variant="outlined">
                                                <Typography>
                                                    <Trans>login.not-connected.could-not-find-player</Trans>
                                                </Typography>
                                            </Alert>
                                        </Grid>
                                        <Grid item>
                                            <Typography>
                                                <Trans>login.not-connected.new-player</Trans>
                                            </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Button color="secondary" fullWidth style={{ padding: 15 }} variant="contained" onClick={handleRegisterDialogOpen}>
                                                <Trans>login.not-connected.new-player-button</Trans>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box my={10} />
                            <Dialog open={state.registerPageDialogOpen} onClose={handleRegisterDialogClose} aria-labelledby="form-dialog-title">
                                <DialogTitle id="form-dialog-title">Registrera som ny spelare</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        <Trans>login.not-connected.new-player.dialog.context</Trans>
                                    </DialogContentText>
                                    <form ref={formRef}>
                                        <Grid container direction="column" spacing={2}>
                                            <Grid item>
                                                <TextField
                                                    autoFocus
                                                    error={checked && (!registerForm.nickname || registerForm.nickname.length === 0)}
                                                    margin="dense"
                                                    inputProps={{
                                                        name: "nickname",
                                                        id: "nickname",
                                                        maxLength: 34,
                                                    }}
                                                    label={t("login.form.nickname")}
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleRegisterFormChange}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        <Trans>login.form.platform</Trans>
                                                    </InputLabel>
                                                    <Select
                                                        error={checked && (!registerForm.platform || registerForm.platform.length === 0)}
                                                        labelId="demo-simple-select-label"
                                                        inputProps={{
                                                            name: "platform",
                                                            id: "platform",
                                                        }}
                                                        value={registerForm.platform}
                                                        onChange={handleRegisterFormChange}
                                                    >
                                                        <MenuItem value={"Epic Games"}>Epic Games</MenuItem>
                                                        <MenuItem value={"Steam"}>Steam</MenuItem>
                                                        <MenuItem value={"Playstation Network"}>Playstation Network</MenuItem>
                                                        <MenuItem value={"Xbox Live"}>Xbox Live</MenuItem>
                                                        <MenuItem value={"Nintendo Switch"}>Nintendo Switch</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    error={checked && (!registerForm.platformId || registerForm.platformId.length === 0)}
                                                    margin="dense"
                                                    inputProps={{
                                                        name: "platformId",
                                                        id: "platformId",
                                                        maxLength: 100,
                                                    }}
                                                    label={t("login.form.platform-id")}
                                                    type="text"
                                                    fullWidth
                                                    onChange={handleRegisterFormChange}
                                                />
                                            </Grid>
                                            <Grid item key={"birthdate"}>
                                                <TextField
                                                    error={checked && (!registerForm.birthdate || registerForm.birthdate.length === 0)}
                                                    fullWidth
                                                    label={t("login.form.birthdate")}
                                                    type="date"
                                                    onChange={handleRegisterFormChange}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        name: "birthdate",
                                                        id: "birthdate",
                                                        maxLength: 100,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item key={"nationality"}>
                                                <Autocomplete
                                                    id="nationalitySelect"
                                                    options={Object.entries(iso3311a2.getData())}
                                                    getOptionLabel={(option) => (i18n.language === "en" ? option[1] : swedishCountries[option[0]])}
                                                    onChange={(event, country) => {
                                                        setRegisterForm({
                                                            ...registerForm,
                                                            nationality: country ? country[0] : undefined,
                                                        });
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            error={checked && (!registerForm.nationality || registerForm.nationality.length === 0)}
                                                            {...params}
                                                            label={t("login.form.nationality")}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <FormControlLabel
                                                    control={<Checkbox checked={checked} onChange={handleChange} name="readRegisterText" />}
                                                    label={t("login.privacy-checkbox-text")}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <Trans>login.privacy-text-part1</Trans>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography>
                                                    <Trans>login.privacy-text-part2</Trans>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleRegisterDialogClose} variant="contained">
                                        <Trans>login.form.back</Trans>
                                    </Button>
                                    <div className={classes.wrapper}>
                                        <Button
                                            onClick={registerAsNewPlayer}
                                            variant="contained"
                                            color="secondary"
                                            disabled={loading || !checked || !registerFormValidWithNickname()}
                                        >
                                            <Trans>login.form.complete</Trans>
                                        </Button>
                                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </div>
                                </DialogActions>
                            </Dialog>
                        </>
                    );
                case "waitingInformation":
                    return (
                        <>
                            <PageTitle minHeight={300} />
                            <SponsorPaper>
                                <Box pt={1} />
                            </SponsorPaper>
                            <Box my={5} />
                            <Container>
                                <Grid container direction="column" spacing={2}>
                                    <Alert severity="success" variant="outlined">
                                        <AlertTitle>
                                            <Trans>login.form.earlier-player-connected.title</Trans>
                                        </AlertTitle>
                                        <Trans>login.form.earlier-player-connected.text</Trans>
                                    </Alert>
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">{t("login.form.platform")}</InputLabel>
                                            <Select
                                                error={checked && (!registerForm.platform || registerForm.platform.length === 0)}
                                                labelId=""
                                                inputProps={{
                                                    name: "platform",
                                                    id: "platform",
                                                }}
                                                value={registerForm.platform}
                                                onChange={handleRegisterFormChange}
                                            >
                                                <MenuItem value={"Epic Games"}>Epic Games</MenuItem>
                                                <MenuItem value={"Steam"}>Steam</MenuItem>
                                                <MenuItem value={"Playstation Network"}>Playstation Network</MenuItem>
                                                <MenuItem value={"Xbox Live"}>Xbox Live</MenuItem>
                                                <MenuItem value={"Nintendo Switch"}>Nintendo Switch</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs>
                                        <TextField
                                            error={checked && (!registerForm.platformId || registerForm.platformId.length === 0)}
                                            margin="dense"
                                            inputProps={{
                                                name: "platformId",
                                                id: "platformId",
                                                maxLength: 100,
                                            }}
                                            label={t("login.form.platform-id")}
                                            type="text"
                                            fullWidth
                                            onChange={handleRegisterFormChange}
                                        />
                                    </Grid>
                                    <Grid item xs key={"birthdate"}>
                                        <TextField
                                            error={checked && (!registerForm.birthdate || registerForm.birthdate.length === 0)}
                                            fullWidth
                                            label={t("login.form.birthdate")}
                                            type="date"
                                            onChange={handleRegisterFormChange}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                name: "birthdate",
                                                id: "birthdate",
                                                maxLength: 100,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs key={"nationality"}>
                                        <Autocomplete
                                            fullWidth
                                            id="nationalitySelect"
                                            options={Object.entries(iso3311a2.getData())}
                                            getOptionLabel={(option) => (i18n.language === "en" ? option[1] : swedishCountries[option[0]])}
                                            onChange={(event, country) => {
                                                setRegisterForm({
                                                    ...registerForm,
                                                    nationality: country ? country[0] : undefined,
                                                });
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={t("login.form.nationality")}
                                                    error={checked && (!registerForm.nationality || registerForm.nationality.length === 0)}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            control={<Checkbox checked={checked} onChange={handleChange} name="readRegisterText" />}
                                            label={t("login.privacy-checkbox-text")}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Trans>login.privacy-text-part1</Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            <Trans>login.privacy-text-part2</Trans>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs>
                                        <div className={classes.wrapper}>
                                            <Button fullWidth onClick={handleAccept} variant="contained" color="secondary" disabled={loading || !checked || !registerFormValid()}>
                                                <Trans>register.button.accept.information</Trans>
                                            </Button>
                                            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                            <Box my={10} />
                        </>
                    );
                case "error":
                default:
                    return <Redirect to="/about" />;
            }
        }
        return (
            <React.Fragment>
                <PageTitle />
                <SponsorPaper>
                    <Box pt={1} />
                </SponsorPaper>
                <Container>
                    <Box mt={5}>
                        <Grid container justify="center">
                            <Grid item>
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
                <Box my={35} />
            </React.Fragment>
        );
    } else if (callbackCode === "logout") {
        React.useEffect(() => {
            logout(cookies, setLoggedIn);
        }, []);
        return <Redirect to="/" />;
    }
};
