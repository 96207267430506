import React from "react";
import { useContext } from "react";
import Button from "@material-ui/core/Button";
import { Box, Divider, CircularProgress, Grid, makeStyles, FormControlLabel, Checkbox } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Trans } from "react-i18next";
import { Constants } from "../../../../../constants";
import { TextField } from "../../../../../components/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { SnackbarContext } from "./../../../../../context/SnackbarProvider";
import Cookies from "universal-cookie";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: "relative",
    },
    buttonProgress: {
        color: theme.palette.secondary.main,
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
}));


export const CopySeasonDialog = (props) => {
    const { season,callback } = props;
    const classes = useStyles();
    const cookies = new Cookies();
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const { setSnackbar } = useContext(SnackbarContext);
    const [newSeasonName, setNewSeasonName] = React.useState(season.name + " Copy");
    const [newSeasonStartDate, setNewSeasonStartDate] = React.useState(season.startdate);
    const [newSeasonEndDate, setNewSeasonEndDate] = React.useState(season.enddate);
    const [newSeasonFormat, setNewSeasonFormat] = React.useState(season.format);
    const [includeDivisons, setIncludeDivisions] = React.useState(false);
    const [includeTeams, setIncludeTeams] = React.useState(false);

    // Functions
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const copySeason = async (seasonid) => {
        let accessToken = cookies.get("accessToken");
        const r = await fetch(`${Constants.apiPrefix}/api/admin/${accessToken}/copySeason/${seasonid}/`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name: newSeasonName,
                startdate: newSeasonStartDate,
                enddate: newSeasonEndDate,
                format:newSeasonFormat,
                includeDivisons:includeDivisons,
                includeTeams:includeTeams
            }),
        });
        return await r.json();
    };

    const clickSave = () => {
         setLoading(true);

         copySeason(season.id).then((result)=>{
            setLoading(false);
            handleClose();
            callback();
         });
    };
    const handleInput = (e, setter) => {
        e.preventDefault();
        setter(e.target.checked);
        console.log(e.target.checked);
    }
    const handleDivisions = (val)=>{
        if(val == false){
            setIncludeTeams(val);
        }
        setIncludeDivisions(val);
    }
    const handleTeams = (val)=>{
        setIncludeTeams(val);
        if(val == true)
            setIncludeDivisions(val);
    };

     // Effects
    React.useEffect(() => {
        // getAllDivisionAdmins().then((r) => setDivisionAdmins(r.MESSAGE));
    }, [season]);

    return (
        <div>
            <Button variant="contained" color="secondary" size="small" onClick={handleClickOpen}>
                <Trans>admin.season.copy</Trans>
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Kopiera säsong "{season.name}"</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Denna funktion kopierar hela säsongen till en ny säsong, inkluderat divisioner och anmälda lag.
                    </DialogContentText>
                    <Grid container direction="column" spacing={1}>
                         <Grid item>
                            <TextField
                                fullWidth
                                label={"Säsongsnamn"}
                                defaultValue={season.name}
                                value={newSeasonName}
                                required={true}
                                onChange={(event) => setNewSeasonName(event.target.value)}
                            />
                        </Grid>
                        <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                        <Grid item>
                            <TextField
                                fullWidth
                                label={"Startdatum"}
                                defaultValue={season.startdate}
                                value={newSeasonStartDate}
                                required={true}
                                helperText="yyyy-mm-dd"
                                onChange={(event) => setNewSeasonStartDate(event.target.value)}
                            />
                        </Grid>
                        <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                        <Grid item>
                            <TextField
                                fullWidth
                                label={"Slutdatum"}
                                defaultValue={season.enddate}
                                value={newSeasonEndDate}
                                required={true}
                                helperText="yyyy-mm-dd"
                                onChange={(event) => setNewSeasonEndDate(event.target.value)}
                            />
                        </Grid>
                        <Divider variant="middle" style={{width: '100%', margin: '10px 0'}} />
                        <Grid item>
                            <TextField
                                fullWidth
                                label={"Format"}
                                defaultValue={season.format}
                                value={newSeasonFormat}
                                required={true}
                                onChange={(event) => setNewSeasonFormat(event.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel 
                                label="Inkludera Divisioner"
                                control={<Checkbox checked={includeDivisons || includeTeams} onChange={(e) => handleInput(e, handleDivisions)} color="secondary" />}
                                labelPlacement="end"
                            />
                        </Grid>
                        <Grid item>
                            <FormControlLabel 
                                label="Inkludera Team"
                                control={<Checkbox checked={includeTeams}  onChange={(e) => handleInput(e, handleTeams)} color="secondary" />}
                                labelPlacement="end"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary">
                        Avbryt
                    </Button>
                    <div className={classes.wrapper}>
                        <Button disabled={loading} onClick={clickSave} color="secondary" variant="contained">
                            Spara
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
}
